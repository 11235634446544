import { createContext, ReactNode, useState } from "react";
import ICareer from "../interfaces/Gupy/ICareer";
import IJob from "../interfaces/Gupy/IJob";

interface IntegracaoGupyContextData {
    jobs: Array<IJob>;
    setJobs: (newJobs: Array<IJob>) => void;
    selectedJob: IJob | undefined;
    setSelectedJob: (newJob: IJob | undefined) => void;
    setCareer: (newCareer: ICareer) => void;
    career: ICareer | undefined;
}

interface IntegracaoGupyProviderProps {
  children: ReactNode;
}

export const IntegracaoGupyContext = createContext(
  {} as IntegracaoGupyContextData
);

export function IntegracaoGupyProvider({ children }: IntegracaoGupyProviderProps) {
    const [jobs, setJobs] = useState<Array<IJob>>([]);
    const [selectedJob, setSelectedJob] = useState<IJob>()
    const [career, setCareer] = useState<ICareer>();
   
    function setJobsFunction(newJobs: Array<IJob>) {
        setJobs(newJobs);
    }

    function setSelectedJobFunction(newJob: IJob | undefined) {
        setSelectedJob(newJob);
    }

    function setCareersFunction(newCareer: ICareer) {
        setCareer(newCareer);
    }

    return (
        <IntegracaoGupyContext.Provider
            value={{
                jobs,
                setJobs: setJobsFunction,
                selectedJob,
                setSelectedJob: setSelectedJobFunction,
                career,
                setCareer: setCareersFunction,
            }}
        >
            {children}
        </IntegracaoGupyContext.Provider>
    )
}