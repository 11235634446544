import { Helmet } from 'react-helmet'
import { Container } from './styles'

function Imports() {
    return (
        <Helmet>
            <script src="https://platform.linkedin.com/in.js" type="text/javascript">lang: pt_BR</script>

            <script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>

            <meta property="og:type" content="website" />
            <meta property="og:title" content="RH Digital" />
            <script
                async
                defer
                crossOrigin="anonymous"
                src="https://connect.facebook.net/pt_BR/sdk.js#xfbml=1&version=v14.0"
                nonce="6DFUZoW8"
            ></script>
        </Helmet>
    )
}

function LinkedinButton() {
    const hrefUrl = window.location.href
    return <script type="IN/Share" data-url={hrefUrl}></script>    
}

function TwitterButton() {
    const hrefUrl = window.location.href
    
    return <a href="https://twitter.com/share?ref_src=twsrc%5Etfw"
            className="twitter-share-button"
            data-url={hrefUrl}
            data-lang="pt" data-show-count="false">Tweet</a>
}

function FacebookButton() {

    const hrefUrl = window.location.href

    return (
        <div>
            <div id="fb-root"></div>
            <div className="fb-share-button"
                data-href={hrefUrl}
                data-layout="button" data-size="small">
                <a target="_blank" className="fb-xfbml-parse-ignore" rel="noreferrer"
                    href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fplugins%2F&amp;src=sdkpreparse"
                >Compartilhar</a>
            </div>
        </div>
    )
}

const ShareButtonsContainer = () => {
    return (
        <>
            <Imports />
            <Container>
                <LinkedinButton />
                <TwitterButton />
                <FacebookButton />
            </Container>
        </>
    )
}

export default ShareButtonsContainer