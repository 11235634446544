import Styled from "styled-components";

export const SocialMediaStyled = Styled.div`
  .containerUrls {
    display: flex;
    flex-wrap: wrap;
    flex-direction: inherit;
    gap: 2rem;
  }
  
  a {
    text-decoration: none;
    text-decoration-color: initial;
    color: #1771ff;
  }

  svg {
    font-size: 20px;
    margin-bottom: 0.2rem;
    margin-right: 0.3rem;
  }

  @media only screen and (max-width: 500px) {
    .containerUrls {
      gap: 0.5rem;
      flex-direction: column;
    }
  }
`;

export const SectionVideoComponent = Styled.div`
  padding-bottom: 2rem;

  .container-embed {
    padding-bottom: 56.25%;
    position: relative;
  }

  iframe {
    top: 0; 
    left: 0;
    position: absolute;
  }
`;

export const AboutHavanComponent = Styled.div`
  padding-bottom: 1rem;
  text-align: justify;

  p {
    margin-top: 0;
    margin-bottom: 0;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-size: 0.92rem!important;
    line-height: 1.5em;
  }

  h1 {
    margin: 0;
  }
`;

export const JobsContainer = Styled.div`
 ul{
    list-style-type: none;

    hr{
      margin: 0.3rem;
    }
  }
`;

export const Title = Styled.h1`
  font-size: 1rem;
  text-transform: uppercase;
  margin-top: 2.5rem;
  margin-bottom: 1.8rem;
  font-weight: bold;
`;

export const ImagesComponent = Styled.div`
  margin: 20px;

  .row {
    .first {
      .imgAbout { height: 653px; }   
    }

    .second {
      display: flex;
      gap: 1.25rem;

      .imgAbout { height: 321px; }
    }

    .third {
      .Principal .imgAbout { height: 430px; }

      .Secondary {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
        
        .imgAbout { height: 205px; }
      }
    }  
    
    @media only screen and (max-width: 992px) {
      flex-direction: column;
      gap: 1.25rem;
      
      .second {
        flex-direction: column;
        gap: 1.25rem;
      }

      .third {
        .Principal { padding: 0; }

        .Secondary {
          gap: 1.25rem;
          padding: 0;
        }
      }
    }

    @media only screen and ((max-width: 992px) and (min-width: 721px)) {
      .imgAbout { height: 430px!important; }
    }

    @media only screen and (max-width: 720px) and (min-width: 661px) {
      .imgAbout { height: 350px!important; }
    }

    @media only screen and (max-width: 660px) and (min-width: 481px) {
      .imgAbout { height: 300px!important; }
    }

    @media only screen and (max-width: 480px) and (min-width: 401px) {
      .imgAbout { height: 220px!important; }
    }

    @media only screen and (max-width: 400px) {
      .imgAbout { height: 150px!important; }
    }
  }
`;

interface ImagesProps {
  url: string | undefined;
}

export const Image = Styled.div<ImagesProps>`
  background-image: url(${(props) => props.url});  
  background-size: cover;
  display: block;
  width: 100%;  
`;

export const Li = Styled.li`
  color: black;

  span{
    font-size: 0.91rem;
    font-weight: bold;
  }

  h4 {
    color: #1771ff;
    line-height: 0.8rem;
    cursor: pointer;
  }

  .container {
    padding: 0 0.3rem;
  }

  .container .row div:nth-child(3)
  {
    text-align: right;
  }

  .container .row {
    --bs-gutter-x: 0!important;
    --bs-gutter-y: 0!important;
  }
`;

export const LoadingContainer = Styled.div`
  display: flex;
  justify-content: center;
`;

export const MapContainer = Styled.div`
  display: flex;
  justify-content: center;
 `;

export const FiltersContainer = Styled.div`
  margin-bottom: 2rem;

  display: flex;
  gap: 1rem;

  @media only screen and (max-width: 500px) {
    flex-direction: column;
  }
`;