import Loader from "../../components/Loading";
import CustomModal from '../CustomModal';

import Styles from './styles.module.scss';

interface LoadingModalProps{
  isLoading: boolean;
}

const LoadingModal = ({isLoading}:LoadingModalProps) => {
  return(
    <CustomModal size={"xs"} open={isLoading} onClose={() => { }}>
      <div className={Styles.loadingContainer}>
        <Loader isLoading={isLoading} />
        <p>Carregando</p>
      </div>
    </CustomModal>
  )
}

export default LoadingModal;