import Styled from 'styled-components';

export const Header = Styled.header`
  background-position: center center;
  background-size: cover;
  display: block;
`

export const Image = Styled.img`
  width: 100%;
  height: auto;
`
