import React from 'react';
import { MainContent, Body } from './styles.modules';
import Footer from '../Footer';
import Header from '../Header';
import ShareButtonsContainer from '../../components/ShareButtonsContainer';
import JobPageHeader from '../../components/JobPageHeader';

const RecruitmentMain: React.FC = ({ children }) => {
    return (
        <Body>
            <JobPageHeader />
            <Header />
            <MainContent>
                <ShareButtonsContainer />
                {children}
            </MainContent>
            <Footer />
        </Body>
    )
}

export default RecruitmentMain