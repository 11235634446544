import React from 'react'

import "./styles.css"

interface ILoader {
    isLoading: boolean;
}

const Loader: React.FC<ILoader> = ({ isLoading }) => (
    <>
        {isLoading &&
            <div className="loader"></div>
        }
    </>
)


export const DotsLoader: React.FC<ILoader> = ({ isLoading }) => (
    <>
        {isLoading &&
            <div className="dots">
                <div></div>
                <div></div>
                <div></div>
            </div>
        }
    </>
)

export default Loader;