import React, { useCallback } from "react";
import { Col } from "react-bootstrap";
import Select from "react-select";
import { FiltersContainer } from "./styles.modules";
import useMerchantDay from "./useMerchantDay";
import { benefit_options, getBenefitTypeName } from "../../enums/EBenefitType";
import DataGrid, {
  Column,
  Scrolling,
  LoadPanel,
} from "devextreme-react/data-grid";
import { IMerchantDayConfig } from "../../interfaces/MerchantDay/IMerchantDay";
import { SeniorPage } from "../../components/SeniorPlatform/SeniorPage";
import { SeniorPanel } from "../../components/SeniorPlatform/SeniorPanel";
import LoadingModal from "../../components/LoadingModal";
import { EPeriodoSolicitacaoDiaComerciario } from "../../enums/EPeriodoSolicitacaoDiaComerciario";

export const MerchantDay: React.FC = () => {
  const {
    loading,
    handleSubmit,
    periodoSolicitacao,
    selectedOption,
    setSelectedOption,
    lastOptions,
  } = useMerchantDay();

  const ConfigPanel = useCallback(() => {
    const periodoText = (() => {
      switch (periodoSolicitacao) {
        case EPeriodoSolicitacaoDiaComerciario.NAO_CADASTRADO:
          return "Período de Solicitação não cadastrado.";
        case EPeriodoSolicitacaoDiaComerciario.DENTRO_DO_PRAZO:
          return "";
        case EPeriodoSolicitacaoDiaComerciario.FORA_DO_PRAZO:
          return "Período de Solicitação fora do prazo.";
      }
    })();

    if (
      periodoSolicitacao !== EPeriodoSolicitacaoDiaComerciario.DENTRO_DO_PRAZO
    )
      return (
        <SeniorPanel>
          <p className="mt-3">{periodoText}</p>
        </SeniorPanel>
      );

    return (
      <SeniorPanel title="Escolher Benefício">
        <form onSubmit={handleSubmit}>
          <FiltersContainer>
            <Col md={3}>
              <label className="mb-2">Opção de Benefício *</label>
              <Select
                placeholder={"Escolha uma opção..."}
                value={selectedOption}
                onChange={setSelectedOption}
                options={benefit_options}
              />
            </Col>
            <div className="d-flex flex-column justify-content-end">
              <button className="btn btn-primary " disabled={!selectedOption}>
                Salvar
              </button>
            </div>
          </FiltersContainer>
        </form>
      </SeniorPanel>
    );
  }, [periodoSolicitacao, handleSubmit, selectedOption, setSelectedOption]);

  const TablePanel = useCallback(() => {
    if (!lastOptions.length) return <></>;

    return (
      <SeniorPanel title="Histórico de Benefícios" className="mt-4">
        <Col lg={6}>
          <DataGrid
            dataSource={lastOptions}
            showColumnLines
            showRowLines
            showBorders
          >
            <Scrolling mode="standard" />
            <LoadPanel enabled text={"Carregando Histórico..."} />
            <Column dataField="AnoBase" caption="ANO" alignment={"center"} />
            <Column
              dataField="TipoBeneficio"
              caption="BENEFÍCIO"
              alignment={"center"}
              cellRender={(options: any) => {
                const row: IMerchantDayConfig = options.data;
                return <span>{getBenefitTypeName(row.TipoBeneficio)}</span>;
              }}
            />
          </DataGrid>
        </Col>
      </SeniorPanel>
    );
  }, [lastOptions]);

  if (loading) return <LoadingModal isLoading />;

  return (
    <SeniorPage title="Dia do Comerciário">
      <ConfigPanel />
      <TablePanel />
    </SeniorPage>
  );
};
