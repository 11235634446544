import styled from "styled-components";

export const Page = styled.div`
  
  margin: 0px;
  position: fixed;
  background: #f2f2f2;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  overflow: auto;
`

export const Content = styled.div`
  padding: 20px;

  @media only screen and (max-width: 767px) {
    padding-inline: 10px;
  }

`

export const Header = styled.div`
  padding: 15px 20px;
  width: 100%;
  height: 70px;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 1px 2px #ddd;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  font-family: Open Sans,sans-serif!important;
  padding-left: 20px;


  @media only screen and (max-width: 767px) {
    padding-inline: 10px;
  }

  h1{
    color: #999;
    font-weight: 400;
    font-size: 16pt;
    margin-right: 15px;
    min-width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 0;
    margin-bottom: 0;
  }
`

export const Panel = styled.div`
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 0!important;
  background-color: #fff;
`

export const Title = styled.div`
  padding-bottom: 15px;
  border-bottom: 1px solid rgb(204, 204, 204);
  margin-bottom: 15px;

  span{
    color: rgb(51, 51, 51);
    font-size: 16px;
    text-transform: uppercase;
  }
`