import Styled from 'styled-components';

export const Title = Styled.h1`
  font-size: 1rem;
  text-transform: uppercase;
  margin-top: 2.5rem;
  margin-bottom: 1.8rem;
  font-weight: bold;
`

export const DetailsContainer = Styled.div`
  display: flex;
  flex-direction: column;

  button{
    margin-top: 2rem;
    max-width: 15rem;
  }

  .sectionCustom {
    padding-bottom: 30px;
    border-bottom: 1px solid #ddd;
  }
`

export const BackButton = Styled.span`
  font-size: 1.2rem;
  font-weight: bold;
  color: blue;
  max-width: 4rem;
  margin-top: 2rem;
  
  cursor: pointer;

  :before{
    content: "Voltar"
  }
`

export const JobDescription = Styled.div``
export const Responsibilities = Styled.div``
export const Requirements  = Styled.div``
export const AdditionalInfo  = Styled.div``
export const HavanDescription = Styled.div``