import React, { createContext, ReactNode, useState } from "react";
import { IDataReceived } from "../components/Notification"

interface NotificationContextData{
  notifications: Array<IDataReceived>;
  addNotification: (notification: IDataReceived) => void;
  removeNotification: (notificationIndex: number) => void;
}

interface NotificationProviderProps{
  children: ReactNode;
}

export const NotificationContext = createContext({} as NotificationContextData);

export function NotificationProvider({children}: NotificationProviderProps){
  const [notifications, setNotifications] = useState<Array<IDataReceived>>([]);

  function addNotification(notification: IDataReceived){
    setNotifications([...notifications, notification]);
  }

  function removeNotification(notificationIndex: number){
    let notificationList = notifications;
    notificationList.splice(notificationIndex, 1);

    setNotifications([...notificationList]);
  }

  return(
    <NotificationContext.Provider
      value={{
        notifications,
        addNotification,
        removeNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}