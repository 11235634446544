import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_URL,
});

api.interceptors.response.use(
  (response) => { return response },
  (error: any) => {

    return Promise.reject(error)
  }
)

export default api;
