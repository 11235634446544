import Spinner from 'react-bootstrap/Spinner'
import { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { R } from 'react-rif';
import Parser from "html-react-parser";

import { FaInstagram, FaLinkedin, FaGlobeAmericas } from "react-icons/fa";
import { Title, JobsContainer, LoadingContainer, MapContainer, FiltersContainer, Image, AboutHavanComponent, SocialMediaStyled, SectionVideoComponent, ImagesComponent } from "./styles.modules";
import IJob from "../../interfaces/Gupy/IJob";
import ICareer from '../../interfaces/Gupy/ICareer';
import Job from "./Job";
import ISelectOptions from '../../interfaces/ISelectOptions';
import BrazilMap from '../../components/BrazilMap';
import { useIntegracaoGupy } from '../../hooks/useIntegracaoGupy';
import { useHistory } from 'react-router-dom';
import { APIGetIntegrationJobs, APIGetIntegrationCareer, APIGetIntegrationBranch } from '../../api/services/IntegracaoGupyAPI';
import { useConfiguracaoGupy } from '../../hooks/useConfiguracaoGupy';
import moment from 'moment';
import IBranch from '../../interfaces/Gupy/IBranch';

const TypeJob: ISelectOptions[] = [
  { label: "Aprendiz", value: 1, disabled: true, description: "vacancy_type_apprentice", },
  { label: "Associado", value: 2, disabled: true, description: "vacancy_type_associate", },
  { label: "Banco de Talentos", value: 3, disabled: false, description: "vacancy_type_talent_pool", },
  { label: "Efetivo", value: 4, disabled: false, description: "vacancy_type_effective", },
  { label: "Estágio", value: 5, disabled: true, description: "vacancy_type_internship", }
];

const JobList = () => {

  const { push } = useHistory()
  const { jobs, setJobs, setSelectedJob: handleSelectedJob, career, setCareer } = useIntegracaoGupy();
  const { images } = useConfiguracaoGupy();

  function handleSelectedJobChange(jobDetail: IJob) {
    handleSelectedJob(jobDetail);
    push(`/vagas/${selectedType}/${jobDetail.id}`);
  }

  const [states, setStates] = useState<Array<ISelectOptions>>([]);
  const [cities, setCities] = useState<Array<ISelectOptions>>([]);
  const [branchs, setBranchs] = useState<Array<ISelectOptions>>([]);

  const [selectedState, setSelectedState] = useState<string[]>([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");

  const [selectedType, setSelectedType] = useState("3");

  const [loadingJobs, setLoadingJobs] = useState(false);

  const [filteredJobs, setFilteredJobs] = useState<Array<IJob>>([]);

  const TypeSelect = () => (
    <select
      name="type_select"
      className="form-control"
    
      onChange={(event) => {
        setSelectedType(event.target.value);
      }}
    >
      {
        TypeJob.filter((type) => !type.disabled).map((type, typeIdx) => (
          <option value={type.value} key={typeIdx} selected={selectedType === String(type.value)} >{type.label}</option>
        ))
      }
    </select>
  )

  const StateSelect = () => (
    <select
      name="state_select"
      className="form-control"
      onChange={(event) => {
        setSelectedState([event.target.value.toUpperCase()]);
        setSelectedCity("-1");
        setSelectedBranch("-1");
      }}
    >
      <option value="-1">Todos</option>
      {
        states.map((state, stateIdx) => (
          <option value={state.value} key={stateIdx} selected={selectedState.some(x => x === state.value.toUpperCase())} >{state.value}</option>
        ))
      }
    </select>
  )

  const CitySelect = () => (
    <select
      name="city_select"
      className="form-control"
      disabled={selectedState.some(x => x === "" || x === "-1")}
      onChange={(event) => {
        setSelectedCity(event.target.value);
        setSelectedBranch("-1");
      }}
    >
      <option value="-1">Todas</option>
      {
        cities.filter(c => selectedState.some(x => x === c.state.toUpperCase())).map((city, cityIdx) => (
          <option value={city.value} key={cityIdx} selected={selectedCity === city.value} >{city.value}</option>
        ))
      }
    </select>
  )

  const BranchSelect = () => (
    <select
      name="branch_select"
      className="form-control"
      disabled={selectedCity === "" || selectedCity === "-1" || selectedState.some(x => x === "" || x === "-1")}
      onChange={(event) => {
        setSelectedBranch(event.target.value);
      }}
    >
      <option value="-1">Todas</option>
      {
        branchs.filter(c => c.city === selectedCity).map((branch, branchIdx) => (
          <option value={branch.value} key={branchIdx} selected={selectedBranch === branch.value} >{branch.value}</option>
        ))
      }
    </select>
  )

  function removeDuplicates(data: any) {
    return data.filter((a: IJob) => {
      return !data[JSON.stringify(a)] && (data[JSON.stringify(a)] = true);
    }, Object.create(null))
      .sort((a: ISelectOptions, b: ISelectOptions) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
  }

  const GetUrlShowEmbeddVideo = (careerObj: ICareer) => {
    let idVideo = careerObj?.videoSection?.videos[0]?.split("/")?.pop();

    if(idVideo?.includes("?") === true)
      idVideo = idVideo.substring(0, idVideo.indexOf("?"))

    return `https://www.youtube.com/embed/${idVideo ?? "GmnayKBiAuc"}`;
  }

  function fetchJobs() {
    setLoadingJobs(true);
    setJobs([]);
    setFilteredJobs([]);
    let totalPage = 100;
    let tipoPublicacaoVaga = 2; //Externa
    APIGetIntegrationJobs(+selectedType, 1, totalPage, tipoPublicacaoVaga)
      .then(async (res) => {
        let results = res.data.results;

        for (let page = 2; page <= res.data.totalPages; page++) {
          let response = await APIGetIntegrationJobs(+selectedType, page, totalPage, tipoPublicacaoVaga)
          results = [...results, ...response.data.results];          
        }

        results = results.filter((x:IJob) => !x.applicationDeadline || (x.applicationDeadline && moment(x.applicationDeadline, "YYYYMMDD").format("YYYYMMDD") >= moment().format("YYYYMMDD")));

        results = await fetchBranches(totalPage, results);
        setJobs(results);
        setFilteredJobs(results);
        setStates(removeDuplicates(
          results.filter((x: IJob) => x.addressState !== "" && x.addressState !== undefined)
            .map((x: IJob) => ({ label: x.addressState.trim(), value: x.addressState.trim() }))
        ));

        setCities(removeDuplicates(
          results.filter((x: IJob) => x.addressCity !== "" && x.addressCity !== undefined)
            .map((x: IJob) => ({ label: x.addressCity.trim(), value: x.addressCity.trim(), state: x.addressState.trim() }))
        ));

        setBranchs(removeDuplicates(
          results.filter((x: IJob) => x.subsidiaryName !== "" && x.subsidiaryName !== undefined && x.subsidiaryName !== null)
            .map((x: IJob) => ({ label: x.subsidiaryName.trim(), value: x.subsidiaryName.trim(), state: x.addressState.trim(), city: x.addressCity.trim() })).sort()
        ));
      })
      .finally(() => {
        setLoadingJobs(false);
      });

      
  }

   async function fetchBranches(totalPage:number, listJobs: any[]){
    const res = await APIGetIntegrationBranch(1, totalPage);
    if (res?.data?.results){
      let results = res.data.results;

      for (let page = 2; page <= res.data.totalPages; page++) {
        let response = await APIGetIntegrationBranch(page, totalPage)
        results = [...results, ...response.data.results];          
      }

      const data = results as IBranch[];
      
      listJobs.forEach(job => {
        const branch = data.find(x=> x.id === job.subsidiaryId);
        if (branch){
          job.addressBranch = branch.addressStreet;
        }
      }); 
    }
    return listJobs;
  }

  function fetchCareerPage(first: boolean = true) {
    setLoadingJobs(true);

     APIGetIntegrationCareer().then(async (data:ICareer) => {
      if(data?.videoSection?.videos?.length > 0) {
        data.videoSection.urlFormatted = GetUrlShowEmbeddVideo(data);
      }
  
      setCareer(data);
  
      if(!first) {
        setLoadingJobs(false);
      }
    });
  }


  function filterJobs() {
    var _jobs = jobs;

    if (selectedState.some(x => x !== "" && x !== "-1")) {
      _jobs = _jobs.filter(j => { return selectedState.some(x => x === j.addressState.toUpperCase()  ) });
    }

    if (selectedCity !== "-1" && selectedCity !== "") {
      _jobs = _jobs.filter(j => j.addressCity === selectedCity);
    }

    if (selectedBranch !== "-1" && selectedBranch !== "") {
      _jobs = _jobs.filter(j => j.subsidiaryName === selectedBranch);
    }

    setFilteredJobs(_jobs);
  }

  useEffect(() => {
    filterJobs();
    // eslint-disable-next-line
  }, [selectedState, selectedCity, selectedBranch])

  useEffect(() => {
    fetchJobs();
    // eslint-disable-next-line
  }, [selectedType]);

  useEffect(() => {
    fetchCareerPage();
    fetchJobs(); 
    handleSelectedJob(undefined); 
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <R if={career !== undefined}>
        <AboutHavanComponent id="content-about">
          <Title>{career?.companySection?.title}</Title>

          {Parser(career?.companySection?.description?.replaceAll("<p><br></p>", "") ?? "")}
        </AboutHavanComponent>
      </R>      

      <ImagesComponent>
        <Row>
          <R if={(images?.UrlImageAbout?.length ?? 0) === 1}>
            <div  className="first">
              <Col xs={12} lg={12} className="Principal">
                <Image className="imgAbout" url={images?.UrlImageAbout[0]?.UrlImagem ?? ""} />
              </Col>
            </div>
          </R>

          <R if={(images?.UrlImageAbout?.length ?? 0) === 2}>            
            <div className="second">
              <Col xs={12} lg={6} className="Principal">
                <Image className="imgAbout" url={images?.UrlImageAbout[0]?.UrlImagem ?? ""} />
              </Col>
              <Col xs={12} lg={6} className="Secondary">
                <Image className="imgAbout" url={images?.UrlImageAbout[1]?.UrlImagem ?? ""} />
              </Col>
            </div>
          </R>

          <R if={(images?.UrlImageAbout?.length ?? 0) >= 3}>
            <div className="third">
              <Row>
                <Col xs={12} lg={8} className="Principal">
                  <Image className="imgAbout" url={images?.UrlImageAbout[0]?.UrlImagem ?? ""} />
                </Col>
                <Col xs={12} lg={4} className="Secondary">
                  <Image className="imgAbout" url={images?.UrlImageAbout[1]?.UrlImagem ?? ""} />
                  <Image className="imgAbout" url={images?.UrlImageAbout[2]?.UrlImagem ?? ""} />
                </Col>
              </Row>              
            </div>
          </R>
        </Row>
      </ImagesComponent> 

      <JobsContainer>
        <Title id='content-jobs'>Deixe seu currículo para nossas oportunidades na cidade de seu interesse</Title>

        <MapContainer>
          <BrazilMap
            states={states.map(s => s.label)}
            handleSelectState={(state: string[]) => {
              setSelectedState(state);
              setSelectedCity("-1");
              setSelectedBranch("-1");
            }}
          />
        </MapContainer>

        <FiltersContainer>
          <Col>
            <span>Tipo de vaga: </span>
            <TypeSelect />
          </Col>
          <Col>
            <span>Estado: </span>
            <StateSelect />
          </Col>
          <Col>
            <span>Cidade: </span>
            <CitySelect />
          </Col>
          <Col>
            <span>Filial: </span>
            <BranchSelect />
          </Col>
        </FiltersContainer>

        <R if={loadingJobs}>
          <LoadingContainer>
            <Spinner animation="border" />
          </LoadingContainer>
        </R>

        <R if={filteredJobs.length > 0}>
          <Col>
            <ul style={{padding: "0"}}>
              {
                filteredJobs.sort((a, b) => (a.name.trim()).localeCompare(b.name.trim())).map(jobDetail =>                (
                  <Job
                    key={jobDetail.code}
                    data={jobDetail}
                    handleSelected={handleSelectedJobChange}
                  />
                ))
              }
            </ul>
          </Col >
        </R >

        <R if={!loadingJobs && (filteredJobs === undefined || filteredJobs.length === 0)}>
          <span>Não há vagas cadastradas no momento.</span>
        </R>

        <R if={career !== undefined}>
          <SocialMediaStyled>
            <Title id="content-know">{career?.socialMediaSection?.title ?? "CONHEÇA MAIS SOBRE NÓS"}</Title>
            <div className="containerUrls">
              <a href={career?.socialMediaSection?.websiteUrl}><FaGlobeAmericas/> Website</a>
              <a href={career?.socialMediaSection?.linkedinUrl}><FaLinkedin/> Linkedin</a>
              <a href={career?.socialMediaSection?.instagramUrl}><FaInstagram/> Instagram</a>
            </div>
          </SocialMediaStyled> 

          <SectionVideoComponent>
            <Title>{career?.videoSection?.title ?? "UM POUCO MAIS SOBRE A HAVAN"}</Title>
            <div className="container-embed">
              <iframe
                width="100%" height="100%"
                src={career?.videoSection?.urlFormatted}
                frameBorder="0" allowFullScreen
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"            
                title="Embedded youtube"
              />
            </div>
          </SectionVideoComponent>
        </R>
        

      </JobsContainer >

    </ >
  )
}

export default JobList;