import { Header as StyledHeader, HeaderDetail as StyledHeaderDetail } from './styles.modules';
import { useConfiguracaoGupy } from "../../hooks/useConfiguracaoGupy";
import logo from "../../assets/logoHavan.svg";
import { useIntegracaoGupy } from '../../hooks/useIntegracaoGupy';

const JobPageHeader = () => {
    const { images } = useConfiguracaoGupy();
    const { selectedJob } = useIntegracaoGupy();

    const src = images?.UrlImageLogo.UrlImagem ?? logo;
    const alt = images?.UrlImageLogo.NomeImagem ?? "Logo Havan";

    function announceOpenToHirePeopleWithDisabilities(): string {
        if (selectedJob?.disabilities) {
            return 'Vaga também para PcD';
        }
        return '';
    }

    function redirectToGupy(): string {
        return `https://lojahavan.gupy.io/candidates/jobs/${selectedJob?.id}/apply?jobBoardSource=gupy_public_page`;

    }

    function redirectToMainPage(): string {
        return "/vagas";
    }

    const Header = () => {
        /*Padrao*/
        return (
            <StyledHeader>
                <div id="headerLogo">
                    <a href={redirectToMainPage()}>
                        <img src={src} alt={alt} />
                    </a>
                </div>
                <div id="headerDetails">
                    <div id='headerTitle'>
                        <h1>Havan S/A</h1>
                    </div>
                    <div id="headerLinks">
                        <div id="headerNavegacao">
                            <a href="#content-about">Sobre</a>
                            <a href="#content-jobs">Vagas</a>
                            <a href="#content-know">Conheça</a> 
                        </div>
                        
                        <a href={"https://lojahavan.gupy.io/candidates"} id="headerBtnLogin">Fazer login como candidato(a)</a>
                    </div>
                </div>
            </StyledHeader>
        )
    }

    if (!selectedJob) {
        return <Header></Header>;
    }

    return (
        /*Pagina Detalhe*/
        <StyledHeaderDetail>

            <div id="headerLogo">
                <a href={redirectToMainPage()}>
                    <img src={src} alt={alt} />
                </a>
            </div>
            <div id="headerAllDetails">
                <div id="headerDetails">
                    <div id='headerTitle'>
                        <h1>{selectedJob?.name}</h1>
                    </div>
                    <div id="headerLinks">                       
                        <a href={redirectToGupy()} id="headerBtnCandidatar">Candidatar-se para a vaga</a>
                    </div>
                </div>
                <div id="headerDetails2">
                    <span>HAVAN S/A</span>
                    <span>{selectedJob.addressCity + '/' + selectedJob.addressStateShortName}</span>
                    <span>Banco de talentos</span>
                    <span>{announceOpenToHirePeopleWithDisabilities()}</span>
                </div>
            </div>
            
        </StyledHeaderDetail>
    )    
}

export default JobPageHeader;