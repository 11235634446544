import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import IJob from '../../interfaces/Gupy/IJob'

import { Li } from "./styles.modules";

interface IJobData{
  data: IJob;
  handleSelected: (jobDetail: IJob) => void;
}

export default function Job({ data, handleSelected }: IJobData) {

  return (
    <>
      <Li>
        <Container>
          <Row style={{ alignItems: 'center' }}>
            <Col xs={12} sm={5} md={5} onClick={() => handleSelected(data)}>
                <h4><span>{data.name}</span></h4>
            </Col>
            <Col xs={12} sm={7} md={7}>
                <span>{data.addressBranch}</span>
            </Col>            
          </Row>
        </Container>
      </Li>
      <hr />
    </>
  )
}
