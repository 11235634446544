import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getSeniorMerchantDayConfig, postSeniorMerchantDayConfig } from "../../api/services/SeniorMerchantDay";
import { getSeniorPlatformUser } from "../../api/services/SeniorPlatformGetUser";
import { EPeriodoSolicitacaoDiaComerciario } from "../../enums/EPeriodoSolicitacaoDiaComerciario";
import ISelectOptions from "../../interfaces/ISelectOptions";
import { IMerchantDayConfig } from "../../interfaces/MerchantDay/IMerchantDay";

export default function useMerchantDay() {

    const { push } = useHistory();

    const [loading, setLoading] = useState(true);
    const [selectedOption, setSelectedOption] = useState<ISelectOptions | null>(null);
    const [lastOptions, setLastOptions] = useState<IMerchantDayConfig[]>([]);
    const [fullName, setFullName] = useState("");
    const [username, setUsername] = useState("");
    const [periodoSolicitacao, setPeriodoSolicitacao] = useState<EPeriodoSolicitacaoDiaComerciario>(0)

    const fetchOptions = useCallback(
        async (fullName: string, username: string) => {
            const data = await getSeniorMerchantDayConfig(fullName, username);
            setLastOptions(data.ListConfiguracaoDiaComerciario);
            setPeriodoSolicitacao(data.SindicatoDiaComerciario.PeriodoSolicitacaoDiaComerciario);
        },
        [],
    );

    function esperaComLimite(ms:number): Promise<void>
    {
        return new Promise((r) => {
            setTimeout(() => {
                r();
            },ms);
        });
    }    

    const checkPermission = useCallback(
        async () => {
            try {
                const { fullName, username } = await getSeniorPlatformUser();
                setFullName(fullName);
                setUsername(username);
                await fetchOptions(fullName, username);
                setLoading(false);
            }
            catch (err) {
                alert("Acesso negado ao acessar a tela!");
                push("/403");
            }
        },
        [push, fetchOptions],
    );
    

    const handleSubmit = useCallback(
        async (evt: React.FormEvent) => {
            evt.preventDefault();

            try {

                await  Promise.race([postSeniorMerchantDayConfig(fullName, username, selectedOption?.value), 
                                    esperaComLimite(10000)]);
                setLoading(true);
                await Promise.race([fetchOptions(fullName, username), 
                    esperaComLimite(10000)]); 
                setLoading(false);
            }
            catch (err) {
                alert("Erro ao salvar opção.\nA página será recarregada");
                window.location.reload();
            }
        },
        [fullName, username, selectedOption, fetchOptions],
    );

    useEffect(() => {
        //HIDE VLIBRAS
        const vLibras = document.querySelector("#vLibras");
        if (vLibras) vLibras.className = "disabled";
        return () => {
            if (vLibras) vLibras.className = "enabled";
        }
    }, [])


    useEffect(() => {
        checkPermission()
    }, [checkPermission])

    return { loading, selectedOption, setSelectedOption, lastOptions, handleSubmit, periodoSolicitacao }
}