import api from "../api";

const baseAPIroute = "/api/ConfiguracoesGupy";

function APIGetGupyConfiguration() {
    return api.get(`${baseAPIroute}`)
}

export {
    APIGetGupyConfiguration
}