import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Hooks from "./hooks";
import 'devextreme/dist/css/dx.light.css';

ReactDOM.render(
  <React.StrictMode>
    <Hooks>
      <App />
    </Hooks>
  </React.StrictMode>,
  document.getElementById("root")
);
