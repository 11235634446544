import { Footer as FooterStyles, FooterLogo } from "./styles.modules";
import { Container, Row, Col } from "react-bootstrap";
import logo from "../../assets/logoHavan.svg";
import { useConfiguracaoGupy } from "../../hooks/useConfiguracaoGupy";

function Footer() {

    const { images } = useConfiguracaoGupy();

    const src = images?.UrlImageLogo.UrlImagem ?? logo;
    const alt = images?.UrlImageLogo.NomeImagem ?? "Logo Havan";

    return (
        <FooterStyles>
            <hr style={{ marginTop: "4rem 0rem 0rem 0rem" }} />
            <Container fluid="md">
                <Row className="content-info">
                    <Col sm={6}>
                        <div style={{ float: "left", textAlign: "left" }}>
                            <p>HAVAN S.A.</p>
                            <p>Rua Antônio Heil, 250 - Centro - Brusque/SC - CEP 88353-100</p>
                        </div>
                    </Col>
                    <Col sm={5}>
                        <div style={{ float: "right", textAlign: "end" }}>
                            <p>Havan labs 2022</p>
                            <p>Todos os Direitos Reservados</p>
                        </div>
                    </Col>
                    <Col sm={1}>
                        <FooterLogo alt={alt} src={src}></FooterLogo>
                    </Col>
                </Row>
            </Container>
        </FooterStyles>
    )
}

export default Footer