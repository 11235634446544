import { Header as HeaderStyle, Image } from "./styles.modules";
import { R } from 'react-rif';
import { useConfiguracaoGupy } from '../../hooks/useConfiguracaoGupy';
import header from "../../assets/havan_header.jpg"; //Imagem padrão 

function Header() {
    const { images } = useConfiguracaoGupy();

    const src = images?.UrlImageHeader.UrlImagem ?? header;
    const alt = images?.UrlImageHeader.NomeImagem ?? "Havan Header";

    return (
        <HeaderStyle>
            <R if={images?.UrlImageHeader !== null}>
                <Image src={src} alt={alt} />
            </R>
        </HeaderStyle>
    )
}

export default Header