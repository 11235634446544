import {
  PageBody,
} from "@havan/react-components";

import { Body } from "./GlobalStyles";
import Notification from "./components/Notification";
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes";

function App() {
  return (
    <BrowserRouter>
      <PageBody>
        <Body >
          <Routes />
          <Notification />
        </Body>
      </PageBody>
    </BrowserRouter>
  );
}

export default App;
