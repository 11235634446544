import { createContext, ReactNode, useEffect, useState } from "react";
import IPageImages from "../interfaces/Gupy/IPageImages";
import { APIGetGupyConfiguration } from "../api/services/ConfiguracaoGupyAPI";

interface ConfiguracaoGupyContextData {
  images: IPageImages | undefined;
  setImages: (newImages: IPageImages | undefined) => void;
}

interface ConfiguracaoGupyProviderProps {
  children: ReactNode;
}

export const ConfiguracaoGupyContext = createContext({} as ConfiguracaoGupyContextData);

export function ConfiguracaoGupyProvider({ children }: ConfiguracaoGupyProviderProps) {
    const [images, setImages] = useState<IPageImages>();

    const pathname = window.location.pathname;

    function setImagesFunction(newImages: IPageImages | undefined) {
        setImages(newImages);
    }

    useEffect(() => {
      if(pathname.includes("vaga"))
        APIGetGupyConfiguration().then((res) => setImages(res.data));
    }, [pathname])

    return (
        <ConfiguracaoGupyContext.Provider
            value={{
                images,
                setImages: setImagesFunction
            }}
        >
            {children}
        </ConfiguracaoGupyContext.Provider>
    )
}