import { Panel, Title } from "../styles.modules"

const SeniorTitle: React.FC = ({ children }) => {
    return (
        <Title>
            {children}
        </Title>
    )
}

interface SeniorPanelProps {
    className?: string
    title?: string;
}

export const SeniorPanel: React.FC<SeniorPanelProps> = ({ children, title, className }) => {
    return (
        <Panel className={className}>
            {title && <SeniorTitle><span>{title}</span></SeniorTitle>}
            {children}
        </Panel>
    )
}