import Parser from "html-react-parser";
import Button from 'react-bootstrap/Button'
import { BackButton, DetailsContainer, Title, JobDescription, Requirements, HavanDescription, Responsibilities, AdditionalInfo } from "./styles.modules";
import { R } from "react-rif";
import { useIntegracaoGupy } from "../../hooks/useIntegracaoGupy";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import LoadingModal from "../../components/LoadingModal";
import { APIGetIntegrationCareer, APIGetIntegrationJobs } from "../../api/services/IntegracaoGupyAPI";
import { FaGlobeAmericas, FaLinkedin, FaInstagram } from "react-icons/fa";
import { SocialMediaStyled } from "../JobsList/styles.modules";
import ICareer from "../../interfaces/Gupy/ICareer";

const JobDetail = () => {

  const { push } = useHistory();
  const params = useParams<{ type: string, jobId: string }>()
  const { selectedJob: job, setSelectedJob, career, setCareer } = useIntegracaoGupy()
  const [isLoadingJob, setIsLoadingJob] = useState(false)

  const redirectToGupy = () => {
    window.location.href = `https://lojahavan.gupy.io/candidates/jobs/${params.jobId}/apply?jobBoardSource=gupy_public_page`;
  }

  function handleBackButtonClick() {
    setSelectedJob(undefined);
    push("/vagas");
  }

  function fetchJob(type: string, jobId: string) {
    setIsLoadingJob(true);
    APIGetIntegrationJobs(+type, 1, 1, +jobId)
    .then((res) => {
      setSelectedJob(res.data.results[0]);
      })
      .catch(() => handleBackButtonClick())
      .finally(() => setIsLoadingJob(false));
  }

  function fetchCareerPage() {
    APIGetIntegrationCareer().then(async (data:ICareer) => { 
      setCareer(data);
    });
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (!job) 
      fetchJob(params.type, params.jobId);

    if (!career)
      fetchCareerPage();

    // eslint-disable-next-line
  }, [params])

  return (
    <>

      <DetailsContainer>
        <LoadingModal isLoading={isLoadingJob} />
        <JobDescription>
          <Title>Sobre a Vaga</Title>
          {Parser(job?.description.replaceAll("<p><br></p>", "") ?? "" ?? "")}
        </JobDescription>

        <R if={job?.responsibilities !== ""}>
          <Responsibilities>
            <Title>Responsabilidades e Atribuições</Title>
            {Parser(job?.responsibilities.replaceAll("<p><br></p>", "") ?? "")}
          </Responsibilities>
        </R>

        <Requirements>
          <Title>Requisitos e Qualificações</Title>
          {Parser(job?.prerequisites.replaceAll("<p><br></p>", "") ?? "" ?? "")}
        </Requirements>

        <R if={job?.additionalInformation !== ""}>
          <AdditionalInfo>
            <Title>Informações adicionais</Title>
            {Parser(job?.additionalInformation.replaceAll("<p><br></p>", "") ?? "")}
          </AdditionalInfo>
        </R>

        <HavanDescription className="sectionCustom">
          <Title>{Parser(career?.companySection?.title ?? "")}</Title>
          {Parser(career?.companySection?.description.replaceAll("<p><br></p>", "") ?? "")}
        </HavanDescription>

        <SocialMediaStyled className="sectionCustom">
          <Title>{career?.socialMediaSection?.title ?? "CONHEÇA MAIS SOBRE NÓS"}</Title>
          <div className="containerUrls">
            <a href={career?.socialMediaSection?.websiteUrl}><FaGlobeAmericas/> Website</a>
            <a href={career?.socialMediaSection?.linkedinUrl}><FaLinkedin/> Linkedin</a>
            <a href={career?.socialMediaSection?.instagramUrl}><FaInstagram/> Instagram</a>
          </div>
        </SocialMediaStyled>

        <Button variant="primary" onClick={redirectToGupy}>Candidatar-se para Vaga</Button>
        <BackButton onClick={handleBackButtonClick} />
      </DetailsContainer>
    </>
  )
}

export default JobDetail;