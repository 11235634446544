import { Redirect, Switch, Route } from "react-router-dom";
import RecruitmentMain from "../layout/RecruitmentMain";
import JobDetail from "../pages/JobDetails";
import JobList from "../pages/JobsList";
import { MerchantDay } from "../pages/MerchantDay";
import { e403 as DeniedPage } from "../pages/403";

const Routes: React.FC = () => (
  <Switch>
    <Route path={"/vagas"}>
      <RecruitmentMain>
        <Switch>
          <Route path={"/vagas"} exact>
            <JobList />
          </Route>
          <Route path={"/vagas/:type/:jobId"}>
            <JobDetail />
          </Route>
        </Switch>
      </RecruitmentMain>
    </Route>

    <Route path={"/diaComerciario"}>
      <MerchantDay />
    </Route>
    
    <Route path={"/403"}>
      <DeniedPage />
    </Route>

    <Route path="*">
      <Redirect to={"/vagas"} exact />
    </Route>
  </Switch>
);

export default Routes;
