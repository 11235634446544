import styled from "styled-components";

export const Container = styled.div`
    margin-bottom: 20px;
    display:flex;
    flex-wrap: wrap;
    justify-content: row;
    align-items: center;
    gap: 8px;
    line-height: 1.0rem;

    button {
        margin: 0!important;
    }

    svg {
        vertical-align: baseline;
    }
` 