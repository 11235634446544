import { Content, Header, Page } from "../styles.modules"

interface SeniorPageProps {
    title: string;
}

const SeniorHeader: React.FC = ({ children }) => {
    return (
        <Header>
            {children}
        </Header>
    )
}

export const SeniorPage: React.FC<SeniorPageProps> = ({ children, title }) => {
    return (
        <Page>
            <SeniorHeader>
                <h1>{title}</h1>
            </SeniorHeader>
            <Content>
                {children}
            </Content>
        </Page>
    )
}