import Styled from 'styled-components';

export const Header = Styled.header`
    font-family: "Lato",sans-serif;
    display: flex;
    padding: 2rem 5.31rem;
    gap: 1rem;

    img {
        width: 100px;
    }

    #headerLogo {
        display: flex;
        align-items: center;
    }

    a {
        text-decoration: none;
        font-size: 1rem;
    }

    #headerTitle {
        float: left;
        font-size: 1.75rem!important;
        font-weight: bold;
        margin: 0;
        width: -webkit-fill-available;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        h1 {
            margin: 0;
            padding: 0;
            font-weight: bold;
            font-size: 1.75rem;
            color: black;
        }
    }

    #headerDetails {
        margin: 0 0 0 1rem;
        height: min-content;
        display: flex;
        gap: 1rem;
        width: 100%;
    }

    #headerLinks {
        display: flex;
        flex-direction: row;
        height: fit-content;
        float: right;
    }

    #headerNavegacao {
        display: flex;
        gap: 1rem;       
    }

    #headerBtnLogin {
        width: max-content;     
        float: right;
        border-radius: 4px;
        border: 0;
        background-color: #1771ff;
        color: #fff;
        font-weight: bold;
        padding: 0.5rem 1.5rem;
        font-size: 0.87rem!important;
    }

    @media only screen and (max-width: 780px)
    {
        h1 {
            font-size: 1.2rem!important;
        }
    }

    @media only screen and (min-width: 1010px)
    {
        #headerDetails {
            border-bottom: 2px solid #dedede;
            padding-bottom: 10px;
        }

        #headerNavegacao {
            display: flex;
            padding-right: 1.87rem;
            align-items: center;

            a {
                margin-left: 1.87rem;
            }
        }
    }

    @media only screen and (max-width: 1009px) 
    {
        padding: 2rem 2rem;

        #headerTitle {
            width: 100%;
            text-align: end;
            border-bottom: 2px solid #dedede;
            padding-bottom: 0.8rempx;
        }

        #headerDetails {
            flex-direction: column;
            gap: 0rem;
            align-items: flex-end;
            width: -webkit-fill-available;
        }

        #headerLinks {
            align-items: flex-end;
            flex-flow: column;
            gap: 0.3rem;
        }
    }

    @media only screen and (max-width: 600px) 
    {
        padding: 1rem 1rem;
    }

    @media only screen and (max-width: 400px) 
    {
        padding: 0.5rem 0.5rem;
        gap: 0.3rem;

        #headerBtnLogin {
            padding: 0.5rem 0.5rem;
            width: fit-content;     
        }

        #headerDetails {
            margin: 0;
        }
    }

    @media only screen and (max-width: 350px)
    {
        #headerLogo {
            display: none;
        }
    }
`;

export const HeaderDetail = Styled.header`
    font-family: "Lato",sans-serif;
    display: flex;
    padding: 2rem 5.31rem;
    gap: 1rem;

    img {
        width: 100px;
    }

    #headerLogo {
        display: flex;
        align-items: center;
    }

    a {
        text-decoration: none;
        font-size: 1rem;
    }

    #headerTitle {
        float: left;
        font-size: 1.75rem!important;
        font-weight: bold;
        margin: 0;
        width: -webkit-fill-available;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        h1 {
            margin: 0;
            padding: 0;
            font-weight: bold;
            font-size: 1.75rem;
            color: black;
        }
    }

    #headerAllDetails {
        width: -webkit-fill-available;
        margin: 0 0 0 1rem;
        list-style-type: none;
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;

        #headerDetails2 {
            padding-top: 0.3rem;

            span {
                font-size: 0.87rem;
                color: #b0b0b0;
            }

            span:not(:last-child) {
                padding-right: 3.12rem;
            }
        }        

        span:last-child {
            float: right;
        }
    }

    #headerDetails {        
        height: min-content;
        display: flex;
        gap: 1rem;
        width: 100%;
    }

    #headerBtnCandidatar {
        width: max-content;     
        float: right;
        border-radius: 4px;
        border: 0;
        background-color: #1771ff;
        color: #fff;
        font-weight: bold;
        padding: 0.5rem 1.5rem;
        font-size: 0.87rem!important;
    }

    @media only screen and (min-width: 1010px)
    {
        #headerDetails {
            border-bottom: 2px solid #dedede;
            padding-bottom: 10px;
        }
    }

    @media only screen and (min-width: 781px) {
        #headerDetails2 span:last-child {
            padding-top: 4px;
        }        
    }

    @media only screen and (max-width: 1009px) 
    {
        padding: 2rem 2rem;

        #headerAllDetails  {

            #headerDetails {
                flex-direction: column;
                gap: 0.4rem;
                align-items: flex-end;
                width: -webkit-fill-available;
            }
        }

        #headerTitle {
            width: 100%;
            text-align: end;
            border-bottom: 2px solid #dedede;
            padding-bottom: 0.8rempx;
        }
    }

    @media only screen and (max-width: 780px)
    {
        #headerLogo {
            position: absolute;
        }

        h1 {
            font-size: 1.2rem!important;
        }

        #headerAllDetails {
            #headerDetails {
                margin-left: 7rem;
            }

            #headerDetails2 {
                display: flex;
                padding-top: 3rem;
                flex-flow: wrap;

                span:last-child {
                    float: none;
                }
            }
        }
    } 

    @media only screen and (max-width: 600px) 
    {
        padding: 1rem 1rem;
    }

    @media only screen and (max-width: 400px) 
    {
        padding: 0.5rem 0.5rem;
        gap: 0.3rem;

        #headerBtnCandidatar {
            padding: 0.5rem 0.5rem;
            width: fit-content;     
        }
    }

    @media only screen and (max-width: 350px)
    {
        #headerLogo {
            display: none;
        }

        #headerDetails {
            margin-left: 0rem!important;
        }

        #headerDetails2 {
            padding-top: 0rem!important;
        }
    }
`;