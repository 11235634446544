import Styled from 'styled-components';

export const Body = Styled.div`
  background-color: #F9F9F9;
`;

export const MainContent = Styled.main`  
  color: #4a4a4a;
  font-family: "Lato",sans-serif;
  background-color: #F9F9F9;
  padding: 3rem 0;
  max-width: 1170px;
  margin: auto;
  font-size: 14px;
  line-height: 1.5em;

  p{
    font-size: 0.92rem;
  }

  @media only screen and (max-width: 600px) {
    padding: 3rem 1rem;
  }

  @media only screen and (min-width: 600px) and (max-width: 1170px) {
    padding: 3rem 0.6rem;
  }
`
