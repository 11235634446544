import ISelectOptions from "../interfaces/ISelectOptions";

export enum EBenefitType {
    FOLGA = 1,
    PAGAMENTO = 2
}

export const benefit_options: ISelectOptions[] = [
    { label: "Folga", value: EBenefitType.FOLGA },
    { label: "Pagamento", value: EBenefitType.PAGAMENTO },
]

export function getBenefitTypeName(benefit: EBenefitType) {
    switch (benefit) {
        case EBenefitType.FOLGA: return "FOLGA";
        case EBenefitType.PAGAMENTO: return "PAGAMENTO";
    }
}