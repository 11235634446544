import React from 'react';
import { Dialog, DialogTitle as MuiDialogTitle, DialogContent, DialogActions, IconButton, } from "@material-ui/core";
import { FaTimes } from "react-icons/fa";
import Styles from './styles.module.css';

interface IDialogHeader {
    title?: string;
    onClose?: () => void;
}

const DialogHeader: React.FC<IDialogHeader> = ({ title, onClose }) => {
    return (
        <>
            <MuiDialogTitle disableTypography className={Styles.dialogModalHeader} >
                <div className={Styles.dialogModalText}>{title}</div>
                {onClose ? (
                    <IconButton aria-label="close" className={Styles.dialogModalButton} onClick={onClose}>
                        <FaTimes />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        </>
    )
}

interface ICustomModal {
    open: boolean;
    onClose: () => void;
    closable?: boolean;
    title?: string;
    submit?: () => void;
    customClassSubmit?: string;
    customTextSubmit?: string;
    disabled?: boolean;
    cancel?: () => void;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}


const CustomModal: React.FC<ICustomModal> = ({ open, closable, onClose, title, submit, customClassSubmit, customTextSubmit, disabled, cancel, size, children }) => {

    const hasHeader = (title || closable);
    const hasActions = (submit || cancel);


    return (
        <Dialog open={open}  maxWidth={size || "lg"} fullWidth className={Styles.dialogModal}>
            {hasHeader && <DialogHeader title={title} onClose={closable ? onClose : undefined} />}
            <DialogContent className={Styles.dialogModalContent}>
                {children}
            </DialogContent>
            {hasActions &&
                <>
                    <DialogActions className={Styles.dialogModalActions}>
                        {cancel && <button className="btn btn-secondary" onClick={cancel}>Cancelar</button>}
                        {submit && <button className={`btn ${customClassSubmit ?? 'btn-primary'}`} disabled={disabled} onClick={submit}>{customTextSubmit ?? 'Confirmar'}</button>}
                    </DialogActions>
                </>
            }
        </Dialog>
    )
}

export default CustomModal
