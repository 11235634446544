import React from "react";

export const e403: React.FC = () => {
  return (
    <div className="m-4 row d-flex text-center" >
      <h3>403</h3>
      <p>Acesso negado</p>
    </div>
  );
};
