import Styled from 'styled-components';

export const Footer = Styled.footer`
  flex-grow: 1;
  margin-top: auto;

  hr {
    opacity: 15%;
    margin: 0px;
    padding: 0px;
  }

  .content-info {
    margin: 0rem 2rem 0rem 2rem;
    font-size: small;
    padding: 0.7rem;
  }

  .content-info p {
    padding: 0;
    margin: 0;
    color: #707070e7
  }

`
export const FooterLogo = Styled.img`
  max-width: 45px;
  max-height: 45px;
`