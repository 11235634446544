import React from "react";

import { NotificationProvider } from "../contexts/Notifications";
import { IntegracaoGupyProvider } from "../contexts/IntegracaoGupy";
import { ConfiguracaoGupyProvider } from "../contexts/ConfiguracaoGupy";

const Hooks: React.FC = ({ children }) => (
    <NotificationProvider>
        <IntegracaoGupyProvider>
            <ConfiguracaoGupyProvider>
                {children}
            </ConfiguracaoGupyProvider>
        </IntegracaoGupyProvider>
    </NotificationProvider>
);

export default Hooks;
