import { EBenefitType } from "../../enums/EBenefitType";
import { IMerchantDayUserConfig, IPOSTMerchantDayUserConfig } from "../../interfaces/MerchantDay/IMerchantDay";
import api from "../api";

export async function getSeniorMerchantDayConfig(fullName: string, username: string) {
    let url = `api/ConfiguracaoDiaComerciarioSenior`;
    url = url.concat(`?matricula=${+username}&nomeCompleto=${fullName}`);

    return await api.get(url)
        .then((res: { data: IMerchantDayUserConfig }) => {
            return res.data;
        });
}

export async function postSeniorMerchantDayConfig(fullName: string, username: string, benefitOption: EBenefitType) {
    let url = "/api/ConfiguracaoDiaComerciarioSenior";

    const data: IPOSTMerchantDayUserConfig = {
        AnoBase: new Date().getFullYear(),
        Matricula: +username,
        NomeCompleto: fullName,
        TipoBeneficio: benefitOption,
    };

    return await api.post(url, data);
}