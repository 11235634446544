import styled from "styled-components";

export const FiltersContainer = styled.div`

  display: flex;
  gap: 1rem;

  @media only screen and (max-width: 500px) {
    flex-direction: column;
  }
`