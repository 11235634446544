import ICareer from "../../interfaces/Gupy/ICareer";
import api from "../api";

const baseAPIroute = "/api/IntegracaoGupy";

const JOBS_CACHE: any = {}

async function APIGetIntegrationCareer() {
    const res = await api.get(`${baseAPIroute}/GetCareerPage?Page=1&TotalPage=1000`);
    return res?.data?.results?.find((x: ICareer) => x.id === 1018);
}

async function APIGetIntegrationJobs(selectedType: number, page: number, totalPage: number, tipoPublicacaoVaga: number, id?: number) {
    const cache_key = `${selectedType}${page}${totalPage}${tipoPublicacaoVaga}`;
    if (JOBS_CACHE[cache_key]) {
        return JOBS_CACHE[cache_key];
    }
    let url = `${baseAPIroute}/GetJobs?TipoVaga=${selectedType}&Page=${page}&TotalPage=${totalPage}`
    if (id) url = url.concat(`&Id=${id}`);
    if (tipoPublicacaoVaga) url = url.concat(`&TipoPublicacaoVaga=${tipoPublicacaoVaga}`);
    const response = await api.get(url);
    JOBS_CACHE[cache_key] = response;
    return response;
}

async function APIGetIntegrationBranch(page: number, totalPage: number) {
    return await api.get(`${baseAPIroute}/GetBranches?Page=${page}&TotalPage=${totalPage}`);
}

export {
    APIGetIntegrationCareer,
    APIGetIntegrationJobs,
    APIGetIntegrationBranch
}